import React from 'react';
import { AddForm } from "../components/AddForm/AddForm";


export const AddFormView = () => {
    return (

        <AddForm/>


    );
};

