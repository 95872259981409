import React from 'react';
import "./Loader.css"

export const Loader = () => (
    <div className="loader-container">
        <div className="loader"></div>
        <p>Loading.... Please Wait</p>
    </div>
);


